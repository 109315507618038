<template>
  <div class="page_" @click="showBg = false">
    <div class="navbar_">
      <div class="main_">
        <img src="../../assets/medilink/about/header.png" alt="" />
        <div class="text_">
          <p>{{ $t('about[0]') || '关于中间带' }}</p>
        </div>
      </div>
      <navbar :showHeader="showHeader" :nowIndex="2"></navbar>
    </div>
    <div class="block_ block_1" id="about1">
      <p class="tit_ animate">{{ $t('about[1]') || '企业简介' }}</p>
      <div class="con_">
        <div class="con1_">
          <img src="../../assets/medilink/about/img.png" alt="" />
          <div class="content_ animate">
            <div class="left_ animate">
              <p class="tit_">
                {{
                  $t('about[5]') || '致力于成为中国最好的健康险解决方案提供商'
                }}
              </p>
              <p class="des_ pre_">
                {{
                  $t('about[7]') ||
                    `中间带（北京）技术服务有限公司是一家专门从事健康保险第三方管理的专业且独立的服务公司，最早起源于拥有业内技术领先的ECCS核心系统的马来西亚MediLink-Global公司，2019年11月加入南燕集团并成为其成员企业。\r\n公司成立于2005年，成立伊始便专注客户服务，提升核心技术，创造数据价值，确保客户数据安全。公司秉承独立、专业、客户利益至上的宗旨，为中外保险公司、再保险公司、保险代理机构、保险经纪公司和大型自保企业提供健康保险第三方管理服务、直付医疗网络以及企业健康服务等，在市场上拥有良好的口碑和领先的品牌。合作几十家保险公司，服务会员超过15万，大型企业超过3200家。`
                }}
              </p>
              <div class="flex_">
                <div class="flex_i">
                  <span class="bold_"
                    ><span class="drop"></span
                    >{{ $t('about[8]') || '使命' }}</span
                  >
                  <span class="pre_">{{
                    $t('about[9]') ||
                      '通过技术创新与资源整合，\n帮助医疗保险体系实现最大价值。'
                  }}</span>
                </div>
                <div class="flex_i">
                  <span class="bold_"
                    ><span class="drop"></span
                    >{{ $t('about[10]') || '愿景' }}</span
                  >
                  <span>{{
                    $t('about[11]') ||
                      '我们的目标是发展、部署和运行电子医疗卡网络，成为全球的领导者和TPA行业的领导者与标准制定者。'
                  }}</span>
                </div>
                <div class="flex_i">
                  <span class="bold_"
                    ><span class="drop"></span
                    >{{ $t('about[12]') || '价值观' }}</span
                  >
                  <span>{{
                    $t('about1[0]') ||
                      '保证数据处理的安全性与专业性；为合作伙伴带来更大的价值；为会员提供高品质的服务'
                  }}</span>
                </div>
              </div>
            </div>
            <div class="right_"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="maincon_">
      <div class="block_" id="about2">
        <p class="tit_ no_pt animate">{{ $t('about1[1]') || '发展历程' }}</p>
        <div class="con_ swiper1">
          <div class="con_c">
            <div class="swiper_btn">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
            <div class="swiper_">
              <swiper ref="mySwiper" :options="swiperOptions">
                <!-- <swiper-slide>
                  <span>2013{{$t('about[13]')||'年'}}</span>
                  <span>AMIA（The Academy of Insurance Medicine of Asia）亚洲保险医学研讨会台北会议金牌赞助商</span>
                </swiper-slide> -->
                <!-- <swiper-slide>
                  <span>2011{{$t('about[13]')||'年'}}</span>
                  <span>AMIA（The Academy of Insurance Medicine of Asia）亚洲保险医学研讨会上海会议金牌赞助商。促成《跨境健康保险理赔研讨会》在京举行，为促进保险公司高端健康保险业务发展、提高理赔技巧提供交流平台</span>
                </swiper-slide> -->
                <!-- <swiper-slide>
                  <span>2010年</span>
                  <span>组织与策划上海世界博览会马来西亚馆特别接待日，受邀出席的有马来西亚卫生部长、医疗旅行委员会首席执行官及私立医疗协会主席等</span>
                </swiper-slide> -->
                <!-- <swiper-slide>
                  <span>2009{{$t('about[13]')||'年'}}</span>
                  <span>AMIA（The Academy of Insurance Medicine of Asia）亚洲保险医学研讨会吉隆坡会议金牌赞助商</span>
                </swiper-slide> -->
                <swiper-slide>
                  <span>2006{{ $t('about[13]') || '年' }}</span>
                  <span>{{
                    $t('about1[3]') ||
                      '拥有自主研发的先进理赔系统，快速便捷地实现客户就医的免现金服务，为业内唯一前置理赔解决方案提供商'
                  }}</span>
                </swiper-slide>
                <swiper-slide>
                  <span>2005{{ $t('about[13]') || '年' }}</span>
                  <span>{{
                    $t('about1[2]') ||
                      '于北京经批准成立，同年设立上海分公司。从事健康保险第三方管理，致力于成为TPA行业的领导者与标准制定者'
                  }}</span>
                </swiper-slide>
                <swiper-slide>
                  <span>2020{{ $t('about[13]') || '年' }}</span>
                  <span>{{
                    $t('about1[8]') ||
                      '在健康险服务领域，合作几十家保险公司，服务会员超过15万，大型团体企业超过3200家'
                  }}</span>
                </swiper-slide>
                <swiper-slide>
                  <span>2019{{ $t('about[13]') || '年' }}</span>
                  <span>{{
                    $t('about1[7]') ||
                      '深耕高端健康险服务领域15年，11月加入南燕集团成为其成员企业'
                  }}</span>
                </swiper-slide>
                <!-- <swiper-slide>
                  <span>2015{{$t('about[13]')||'年'}}</span>
                  <span>AMIA（The Academy of Insurance Medicine of Asia）亚洲保险医学研讨会香港会议金牌赞助商</span>
                </swiper-slide> -->
                <swiper-slide>
                  <span>2014{{ $t('about[13]') || '年' }}</span>
                  <span>{{
                    $t('about1[5]') ||
                      '荣获‘ISO9001:2008系认证证书’以及‘ISO/IEC27001信息安全管理体系认证证书’权威认证'
                  }}</span>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="block_" id="about3">
        <p class="tit_ no_pt">企业荣耀</p>
        <div class="con_ swiper2">
          <swiper ref="mySwiper1" :options="swiperOptions1">
            <swiper-slide>
              <div class="mask_"></div>
              <img src="../../assets/medilink/about/img1.png" alt="" />
              <div class="des_">
                <p>标题标题标题标题标题标题</p>
                <p>
                  内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="mask_"></div>
              <img src="../../assets/medilink/about/img2.png" alt="" />
              <div class="des_">
                <p>标题标题标题标题标题标题</p>
                <p>
                  内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="mask_"></div>
              <img src="../../assets/medilink/about/img1.png" alt="" />
              <div class="des_">
                <p>标题标题标题标题标题标题</p>
                <p>
                  内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div> -->
      <div class="block_" id="about3">
        <p class="tit_ no_pt animate">{{ $t('about1[9]') || '商业模式' }}</p>
        <div class="con_ flex_ flex_n">
          <img src="../../assets/medilink/about/moshi.jpg" alt="" />
          <div class="right_c animate">
            <div class="right_ci">
              <span>01</span>
              <div class="right_cir">
                <span>{{ $t('Modals[26]') || '被保险人' }}</span>
                <p>
                  <span class="drop"></span
                  >{{
                    $t('about1[11]') ||
                      '尽享医疗机构的免现金服务，感受就医的便捷'
                  }}
                </p>
                <p>
                  <span class="drop"></span
                  >{{ $t('about1[12]') || '免去繁琐的理赔手续；' }}
                </p>
                <p>
                  <span class="drop"></span
                  >{{ $t('about1[13]') || '避免现金携带不足的尴尬。' }}
                </p>
              </div>
            </div>
            <div class="right_ci">
              <span>02</span>
              <div class="right_cir">
                <span>{{ $t('about1[14]') || '医疗机构' }}</span>
                <p>
                  <span class="drop"></span
                  >{{
                    $t('about1[15]') ||
                      '提升患者的就医感受，提升对医院的满意度；'
                  }}
                </p>
                <p>
                  <span class="drop"></span
                  >{{ $t('about1[16]') || '带来稳定的业务量；' }}
                </p>
                <p>
                  <span class="drop"></span
                  >{{
                    $t('about1[17]') ||
                      '国际标准的医疗机构与保险公司间的合作关系。'
                  }}
                </p>
              </div>
            </div>
            <div class="right_ci">
              <span>03</span>
              <div class="right_cir">
                <span>{{ $t('cols[6]') || '保险公司' }}</span>
                <p>
                  <span class="drop"></span
                  >{{ $t('about1[19]') || '增强保险产品对投保者的吸引力；' }}
                </p>
                <p>
                  <span class="drop"></span
                  >{{ $t('about1[20]') || '降低理赔管理中的行政支出；' }}
                </p>
                <p>
                  <span class="drop"></span
                  >{{
                    $t('about1[21]') ||
                      '实现安全、便捷的理赔与结算，减少医疗支出的风险。'
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="block_" id="about4">
        <p class="tit_ no_pt animate">联系我们</p>
        <div class="con_" @click.stop>
          <Map :chartData="chartData" v-on:countryClick="handleClick"></Map>
        </div>
      </div> -->
      <div class="block_" id="about5">
        <p class="tit_ no_pt animate">
          {{ $t('about1[22]') || '部分合作伙伴' }}
        </p>
        <div class="con_ swiper3">
          <div class="con_">
            <swiper ref="mySwiper2" :options="swiperOptions2">
              <swiper-slide>
                <img src="../../assets/medilink/about/logo1.png" alt="" />
                <img src="../../assets/medilink/about/logo2.png" alt="" />
                <img src="../../assets/medilink/about/logo3.png" alt="" />
                <img src="../../assets/medilink/about/logo4.png" alt="" />
                <img src="../../assets/medilink/about/logo5.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="../../assets/medilink/about/logo6.png" alt="" />
                <img src="../../assets/medilink/about/logo7.png" alt="" />
                <img src="../../assets/medilink/about/logo8.png" alt="" />
                <img src="../../assets/medilink/about/logo9.png" alt="" />
                <img src="../../assets/medilink/about/logo10.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="../../assets/medilink/about/logo11.png" alt="" />
                <img src="../../assets/medilink/about/logo12.png" alt="" />
                <img src="../../assets/medilink/about/logo13.png" alt="" />
                <img src="../../assets/medilink/about/logo14.png" alt="" />
                <img src="../../assets/medilink/about/logo15.png" alt="" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <!-- <div class="block_" id="about6">
        <p class="tit_ no_pt animate">招贤纳士</p>
        <div class="con_ con_z">
          <div class="tit_1">
            <span>本期热门岗位</span>
            <span
              v-for="(item, index) in zhaopin"
              :class="{ on: nowindex === index }"
              @click="changeNow(index)"
              >{{ item.position }}</span
            >
          </div>
          <div class="flex_b">
            <div class="flex_">
              <div class="flex_l">
                <p style="font-size:15px;font-weight:bold;margin-bottom:10px;">
                  {{ nowitem.position }}
                </p>
                <p class="gray_">
                  <span>工作经验：{{ nowitem.time }}</span>
                  <span>工作地点：{{ nowitem.loc }}  </span>
                  <span>薪资福利：{{ nowitem.money }}</span>
                </p>
                <p class="bold_">岗位描述</p>
                <p class="des_" v-html="nowitem.request"></p>
                <p class="bold_">职位要求</p>
                <p class="des_" v-html="nowitem.condition"></p>
              </div>
              <div class="flex_r">
                <img src="../../assets/medilink/about/map.png" alt="" />
                <div class="r_des">
                  <p>
                    <span class="loc_"></span
                    >地址：北京市朝阳区建国门外大街丙24号，京泰大厦10层
                  </p>
                  <p>
                    <span class="email_"></span
                    >邮箱：yu.fu@medilink-global.com.cn
                  </p>
                  <p><span class="tag_"></span>邮编：100000</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <footerbar></footerbar>
    <div class="fixbg_" v-if="showBg">
      <div class="con_" v-if="bgIndex === 1" @click.stop>
        <p class="tit_">{{ data.name }}</p>
        <p class="adress_" v-html="data.adress"></p>
        <!-- <p>Tel: +(65) 6885 0268 (General Line</p> -->
        <p style="margin-top:10px;">{{ data.contact }}</p>
        <p class="email">sales@medilink-global.com</p>
        <div class="btn">
          <span :class="{ on: bgIndex === 1 }" @click="bgIndex = 1">{{
            data.btntext[0]
          }}</span>
          <span :class="{ on: bgIndex === 2 }" @click="bgIndex = 2">{{
            data.btntext[1]
          }}</span>
        </div>
      </div>
      <div class="con_" v-if="bgIndex === 2" @click.stop>
        <p class="tit_">{{ data.name }}</p>
        <div class="iitem">
          {{ data.formtext[0] }}:<input trpe="text" v-model="form.name" />
        </div>
        <div class="iitem">
          {{ data.formtext[1] }}:<input trpe="text" v-model="form.email" />
        </div>
        <div class="iitem">
          {{ data.formtext[2] }}:<a-select
            v-model="form.subject"
            style="width:200px;"
          >
            <a-select-option :value="data.selecttext[0]">{{
              data.selecttext[0]
            }}</a-select-option>
            <a-select-option :value="data.selecttext[1]">{{
              data.selecttext[1]
            }}</a-select-option>
            <a-select-option :value="data.selecttext[2]">{{
              data.selecttext[2]
            }}</a-select-option>
            <a-select-option :value="data.selecttext[3]">{{
              data.selecttext[3]
            }}</a-select-option>
          </a-select>
        </div>
        <div class="iitem">
          {{ data.formtext[3] }}:<input trpe="text" v-model="form.message" />
        </div>
        <div class="iitem"><span @click="submitInfo">submit</span></div>
        <div class="btn">
          <span :class="{ on: bgIndex === 1 }" @click="bgIndex = 1">{{
            data.btntext[0]
          }}</span>
          <span :class="{ on: bgIndex === 2 }" @click="bgIndex = 2">{{
            data.btntext[1]
          }}</span>
        </div>
      </div>
      <span class="close_"></span>
    </div>
    <sideBar v-show="showBar"></sideBar>
  </div>
</template>
<script>
import footerbar from './components/footer.vue'
import navbar from './components/navbar.vue'
import sideBar from './components/sideBar.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import '../../../node_modules/echarts/map/js/world.js' // 引入中国地图数据
export default {
  data() {
    return {
      showHeader: false,
      height: 0,
      showBar: false,
      swiperOptions: {
        direction: 'vertical',
        slidesPerView: 9,
        loop: true,
        centeredSlides: true,
        // autoplay: {
        //   delay: 2000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptions1: {
        slidesPerView: 3,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        }
      },
      swiperOptions2: {
        direction: 'vertical',
        slidesPerView: 3,
        // loop:true,
        spaceBetween: 1,
        centeredSlides: false
        // effect: 'fade',
        // autoplay: {
        //   delay: 2000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },
      },
      chart: null,
      chartData: [],
      data: '',
      zhaopin: [
        {
          position: '英语客服',
          time: '',
          loc: '北京/上海',
          money: '7-15k',
          request:
            '1、负责7*24小时客户热线咨询、信息查询及疑难问题的解答工作。<br>2、根据业务需求给客户拨打电话进行各种告知、说明、满意度调查；<br>3、受理客户的争议，安抚客户并及时准确知会相关人员，做好及时处理和追踪；<br>4、收集、整理服务过程中客户反应的问题，结合客户要求提供最佳的解决方案；<br>5、协调公司内、外部问题沟通，确保服务渠道畅通；<br>6、完成电话记录数据提交，单据核对以及报表准备；<br>7、配合其他同事与运营部的沟通，明确客户需求，获得相应支持；<br>8、能和团队成员一起对公司所有产品提出合理性建议和使用意见，并提供可行性解决方案；',
          condition:
            '1、保险/护理/医学大专以上学历<br>2、年龄35岁以下，普通话标准，有较强的沟通能力和服务意识，有良好的职业素养<br>3、有医疗或保险客服经验，护理学专业优先，会英语、韩语优先<br>4、有良好的执行力和团队合作精神，能承担一定的工作压力'
        },
        {
          position: '客户经理',
          time: '',
          loc: '北京/上海',
          money: '6-20k',
          request:
            '1、熟悉公司业务，积极市场开拓；<br>2、积极开展公司产品销售，按照要求完成公司制定的销售目标；<br>3、准备合同初期文档、报价和服务条款等，积极与客户沟通；<br>4、支持售前、售后工作，提供市场业务解决方案；<br>5、对客户进行专业的管理，并提供优化公司服务和流程的建议；<br>6、参与售后服务的实施、协调，包括客户培训及支持；<br>7、根据公司需要，负责和参与新产品开发、制定、推广和销售；<br>8、了解和整理市场信息，积极寻找和探索产品推广与销售渠道；<br>9、根据客户信息，整理出有市场价值的资料，供市场开拓和推广使用。',
          condition:
            '1、大学本科以上学历，具备较高的英文听、说、读、写能力，能进行流利的英语进行沟通，医疗行业背景优先；<br>2、有较强的逻辑分析、创新、沟通和书面表达能力；<br>3、具有较强的销售经验和能力；<br>4、具有一定的领导能力、判断与决策能力、协调能力、影响力、计划与执行能力'
        },
        {
          position: '商务代表',
          time: '',
          loc: '北京/上海',
          money: '6-20k',
          request:
            '1.系统维护网络医疗机构信息，跟进高端医疗险市场的信息变化以及高端医疗服务机构的开拓流程。<br>2.整合医疗资源，跟进医疗市场动态。<br>3.及时反馈公司会员变动情况及其他相关信息到各个医疗机构网络和服务提供商。',
          condition:
            '1.本科以上学历，英语6级以上，听说读写兼顾；<br>2.性格外向愿意对外与医疗机构交流；<br>3.有商科背景或者医疗机构从业背景优先，<br>4.有基本的数据分析能力'
        }
      ],
      nowindex: 0,
      nowitem: {
        position: '英语客服',
        time: '',
        loc: '北京/上海',
        money: '7-15k',
        request:
          '1、负责7*24小时客户热线咨询、信息查询及疑难问题的解答工作。<br>2、根据业务需求给客户拨打电话进行各种告知、说明、满意度调查；<br>3、受理客户的争议，安抚客户并及时准确知会相关人员，做好及时处理和追踪；<br>4、收集、整理服务过程中客户反应的问题，结合客户要求提供最佳的解决方案；<br>5、协调公司内、外部问题沟通，确保服务渠道畅通；<br>6、完成电话记录数据提交，单据核对以及报表准备；<br>7、配合其他同事与运营部的沟通，明确客户需求，获得相应支持；<br>8、能和团队成员一起对公司所有产品提出合理性建议和使用意见，并提供可行性解决方案；',
        condition:
          '1、保险/护理/医学大专以上学历<br>2、年龄35岁以下，普通话标准，有较强的沟通能力和服务意识，有良好的职业素养<br>3、有医疗或保险客服经验，护理学专业优先，会英语、韩语优先<br>4、有良好的执行力和团队合作精神，能承担一定的工作压力'
      },
      showBg: false,
      bgIndex: 1,
      form: {
        name: '',
        email: '',
        subject: '',
        message: '',
        countryName: ''
      },
      countryList: [
        {
          name: 'MEDILINK IN AMERICA',
          adress:
            'Registered Office Address:Numas, INC <br> 8611 NE 35th Ave,<br> USA',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: 'Medilink-Global UK Limited',
          adress:
            'Registered Office Address:Queensway House, <br> Hilgrove Street, St Helier, JE1 1ES<br> Tel: +(65) 6885 0268 (General Line)',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: 'MEDILINK BANGLADESH',
          adress:
            'Highway Market (3rd Floor), <br> 678-B, CDA Avenue,<br> Lalkhan Bazar, Chittagong,<br> Bangladesh',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: 'MEDILINK Thailand',
          adress:
            '404, Phaholyothin Road, Samsen Nai Sub-District,<br> Phayathai District, BANGKOK<br> 10400',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: 'MEDILINK Malaysia',
          adress:
            '4th & 5th Floor, Syed Kechik Foundation Building,<br> Jalan Kapas, Bangsar<br> 59100 Kuala Lumpur<br>Malaysia<br>Tel: +603 2296 3000(General Line); Fax: +603 2282 8571',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: 'MEDILINK Singapore',
          adress:
            'Medilink-Global (Asia) Pte Ltd:105 Cecil Street,#06-01 The Octago,069534 Singapore,Tel: (65) 68850268 (General Line)<br> Medilink-Global TPA Pte Ltd:(Formerly known as Lifeinc Holdings Pte Ltd),Tel: (65) 6255 7757,Fax: (65) 6255 7747',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: 'MEDILINK Vietnam',
          adress: '',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: 'Medilink-Global (HK) Limited',
          adress:
            'Registered Office Address: 36F, Tower Two, Time Square,<br>1 Matheson Street, Causeway Bay, Hong Kong<br> Tel: +(65) 6885 0268 (General Line)',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        },
        {
          name: '中国',
          adress:
            '北京：北京市朝阳区建国门外大街丙24号京泰大厦10层 邮编100022  电话：+8610 8453 9718；传真：+8610 8453 9719<br>上海：上海市浦东新区陆家嘴东路161号招商局大厦1902室 邮编200120 电话：+8621 6859 6828；传真：+8621 6859 6829',
          contact: 'You may contact the office directly at',
          formtext: ['Name', 'Email', 'Subject', 'Message'],
          btntext: ['VIEW OFFICE DETAILS', 'CONTACT OFFICE'],
          selecttext: [
            'General question',
            'Technical Question',
            'Investor query',
            'Complaint'
          ]
        }
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll()
    }
    let loc = this.$route.query.loc
    if (loc != undefined && loc != null) {
      document.getElementById('about' + loc).scrollIntoView()
    }
    this.startAnimation()
  },
  beforeRouteUpdate(to) {
    if (to.query.loc) {
      document.getElementById('about' + to.query.loc).scrollIntoView()
    }
  },
  components: {
    footerbar,
    navbar,
    Swiper,
    SwiperSlide,
    sideBar
  },
  directives: {
    swiper: directive
  },
  methods: {
    handleScroll() {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      )
      this.height = top
      this.startAnimation()
    },
    startAnimation() {
      let elementArr = document.querySelectorAll('.animate')
      let bodyHeight = document.body.clientHeight
      let heightArr = []
      for (var i = 0; i < elementArr.length; i++) {
        heightArr.push(elementArr[i].offsetTop + 100)
      }
      for (var j = 0; j < heightArr.length; j++) {
        if (
          heightArr[j] >= this.height &&
          heightArr[j] < this.height + bodyHeight
        ) {
          elementArr[j].classList.add('slideUpIn60')
        }
      }
    },
    changeNow(index) {
      this.nowindex = index
      this.nowitem = this.zhaopin[index]
    },
    handleClick(name) {
      let nameArr = [
        '美国',
        '英国',
        '孟加拉国',
        '泰国',
        '马来西亚',
        '新加坡',
        '越南',
        '香港',
        '中国'
      ]
      if (nameArr.indexOf(name) > -1) {
        this.showBg = true
        this.form.countryName = name
        this.data = this.countryList[nameArr.indexOf(name)]
      }
    },
    submitInfo() {
      if (
        this.form.name === '' ||
        this.form.email === '' ||
        this.form.subject === '' ||
        this.form.message === ''
      ) {
        this.$notification.open({
          message: '输入提醒',
          description: '所填项均不能为空，请重新填写',
          duration: 4
        })
      } else {
        this.form.status = '1'
        this.$apis.sendMailToWeb(this.form).then(res => {
          if (res.data.code === 200) {
            this.$notification.open({
              message: '提交提醒',
              description: '提交成功！',
              duration: 4
            })
            this.step = 1
          }
        })
      }
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true
          if (newHeight > oldHeight) {
            this.showHeader = false
          } else {
            this.showHeader = true
          }
        } else {
          this.showHeader = false
          this.showBar = false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page_ {
  padding: 0;
  background-color: #fff;
  .pre_ {
    white-space: pre-wrap;
  }
  p {
    margin: 0;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    .main_ {
      width: 100%;
      position: relative;
      color: #fff;
      & > img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 33px;
        text-align: center;
      }
    }
  }
  .maincon_ {
    background: url(../../assets/medilink/about/bg.jpg) center center no-repeat;
    background-size: 100% 100%;
  }
  .block_ {
    width: 100%;
    color: #000000;
    font-size: 14px;
    &.block_1 {
      background: url(../../assets/medilink/about/bg1.png) center center
        no-repeat;
      background-size: 100% 100%;
    }
    .right_c {
      p {
        text-align: left;
      }
    }
    p {
      text-align: center;
    }
    .flex_ {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > .tit_ {
      font-size: 20px;
      font-weight: bold;
      padding: 91px 0;
    }
    .drop {
      width: 4px;
      height: 4px;
      background-color: #00a63c;
      display: inline-block;
      margin: 0 16px;
    }
    .bold_ {
      font-weight: bold;
    }
    .con_ {
      color: #333;
      font-size: 14px;
      .con_c {
        position: relative;
        width: 950px;
        height: 500px;
        margin: 0 auto;
        .swiper_btn {
          position: absolute;
          width: 39px;
          height: 88px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          top: 215px;
          left: 31px;
          background-color: #fff;
        }
        .swiper-button-prev {
          position: static;
          top: auto;
          width: 39px;
          height: 22px;
          background: url(../../assets/medilink/about/previcon.png) center
            center no-repeat #fff;
          background-size: 39px 22px;
          opacity: 0.8;
          outline: none;
          &:hover {
            opacity: 1;
          }
          &::after {
            display: none;
          }
        }
        .swiper-button-next {
          position: static;
          top: auto;
          width: 39px;
          height: 22px;
          background: url(../../assets/medilink/about/nexticon.png) center
            center no-repeat #fff;
          background-size: 39px 22px;
          opacity: 0.8;
          outline: none;
          &:hover {
            opacity: 1;
          }
          &::after {
            display: none;
          }
        }
        .swiper_ {
          width: 900px;
          height: 500px;
          position: absolute;
          top: 0;
          right: 0;
          border-left: 1px solid #eeeeee;
        }
      }
      .con1_ {
        width: 1000px;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 291px;
          height: 312px;
          border-radius: 10px;
          object-fit: cover;
        }
        .content_ {
          display: flex;
          align-items: initial;
          justify-content: space-between;
          right: 64px;
          width: 660px;
          padding: 0 0;
        }
        .left_ {
          width: 640px;
          text-align: left;
          .tit_ {
            font-size: 17px;
            font-weight: bold;
            text-align: left;
            letter-spacing: 1px;
            margin-bottom: 9px;
          }
          .des_ {
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
          .flex_ {
            align-items: initial;
            justify-content: space-between;
            margin-top: 40px;
          }
          .flex_i {
            width: 30%;
            &:nth-child(1) {
              width: 27%;
            }
            &:nth-child(3) {
              width: 35%;
            }
            span {
              display: flex;
              align-items: center;
              font-size: 13px;
              &:first-child {
                font-size: 15px;
                font-weight: bold;
              }
              .drop {
                background-color: #00a63c;
                margin-left: 0;
              }
            }
          }
        }
      }
      &.swiper1 .swiper-container {
        padding-left: 10px;
        width: 900px;
        margin: 0 auto;
        height: 480px;
        margin-left: -10px;
        .swiper-slide {
          height: 50px;
          // line-height: 50px;
          display: flex;
          align-items: center;
          padding-left: 50px;
          z-index: 999;
          span {
            &:first-child {
              font-size: 17px;
              font-weight: bold;
              min-width: 100px;
              margin-right: 20px;
            }
            &:last-child {
              color: #999;
              font-size: 14px;
            }
          }
          &.swiper-slide-prev {
            border-bottom: 1px solid #eeeeee;
          }
          &.swiper-slide-next {
            border-top: 1px solid #eeeeee;
          }
          &.swiper-slide-active {
            height: 72px !important;
            width: 910px;
            border-left: none;
            // line-height: 72px;

            border-radius: 15px;
            position: relative;
            background-color: #fff;
            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto 0;
              left: -4px;
              width: 10px;
              height: 80px;
              background-color: #fff;
              z-index: 100;
            }
            span:first-child {
              color: #00a63c;
              position: relative;
              &::after {
                display: block;
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                background-color: #00a63c;
                z-index: 100;
                top: 0;
                bottom: 0;
                left: -55px;
                margin: auto 0;
                transform: rotate(-136deg);
              }
            }
            span:last-child {
              color: #000;
            }
          }
        }
      }
      &.swiper2 .swiper-container {
        padding: 40px 0;
        width: 1000px;
        margin: 0 auto;
        .swiper-slide {
          position: relative;
          transition: 300ms;
          transform: scale(1);
          img {
            width: 100%;
            height: 199px;
            object-fit: cover;
          }
          .mask_ {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
          }
          .des_ {
            display: none;
            padding: 0 30px 30px;
            p {
              margin: 0;
              text-align: center;
              color: #000;
              font-size: 14px;
              line-height: 18px;
              &:first-child {
                font-size: 15px;
                padding: 10px 0;
                font-weight: bold;
                text-align: center;
              }
            }
          }
          &.swiper-slide-active {
            transform: scale(1.4);
            z-index: 99;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .des_ {
              display: block;
            }
            .mask_ {
              background: transparent;
            }
          }
        }
      }
      &.swiper3 .swiper-container {
        width: 1200px;
        margin: 0 auto;
        height: 340px;
        .swiper-slide {
          display: flex;
          justify-content: space-between;
          height: 82px;
          padding: 0px 0;
          img {
            width: 20%;
            height: auto;
            object-fit: cover;
          }
          &.swiper-slide-prev {
            animation: radius 1s ease-in-out 1;
          }
          @keyframes radius {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0.5;
            }
            100% {
              opacity: 0;
            }
          }
        }
      }
      &.con_z {
        width: 100%;
        .tit_1 {
          width: 1000px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 14px;
            background: rgba(0, 0, 0, 0.02);
            color: #000;
            border: 1px solid rgba(0, 0, 0, 0.01);
            height: 30px;
            line-height: 30px;
            width: 113px;
            text-align: center;
            border-radius: 2px;
            margin-right: 15px;
            cursor: pointer;
            &:hover {
              font-weight: bold;
            }
            &:first-child {
              font-weight: bold;
              font-size: 16px;
              background-color: transparent;
              width: auto;
              cursor: initial;
              &:hover {
                font-weight: normal;
              }
            }
            &.on {
              border: 1px solid #000000;
              font-weight: bold;
            }
          }
        }
        .flex_b {
          width: 100%;
          background-color: #f9f9f9;
          margin-top: 30px;
          padding: 30px 0 50px;
        }
        .flex_ {
          width: 1000px;
          margin: 0 auto;
          justify-content: space-between;
          .bold_ {
            font-weight: bold;
            color: #000;
            font-size: 14px;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          p {
            text-align: left;
            margin: 0;
            line-height: 20px;
          }
          .gray_ {
            color: #666;
          }
          .flex_l {
            width: 500px;
            font-size: 14px;
          }
          .flex_r {
            width: 420px;
            img {
              width: 100%;
              height: 250px;
              object-fit: cover;
            }
            .r_des {
              margin-top: 20px;
            }
            p {
              display: flex;
              align-items: center;
              font-size: 14px;
              line-height: 25px;
              .loc_ {
                display: inline-block;
                width: 12px;
                height: 15px;
                background: url(../../assets/medilink/about/loc.png) center
                  center no-repeat;
                background-size: 12px 15px;
                margin-right: 10px;
              }
              .email_ {
                display: inline-block;
                width: 13px;
                height: 10px;
                background: url(../../assets/medilink/about/email.png) center
                  center no-repeat;
                background-size: 13px 10px;
                margin-right: 10px;
              }
              .tag_ {
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url(../../assets/medilink/about/tag.png) center
                  center no-repeat;
                background-size: 14px 14px;
                margin-right: 10px;
              }
            }
          }
        }
      }
      &.flex_n {
        width: 1000px;
        margin: 0 auto;
        > img {
          width: 474px;
          height: 100%;
          object-fit: cover;
        }
        .right_c {
          margin-left: 70px;

          .right_ci {
            display: flex;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            > span {
              font-size: 50px;
              color: #00a63c;
              margin-right: 22px;
            }
            .right_cir {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              font-size: 14px;
              color: #333;
              line-height: 23px;
              padding-top: 12px;
              .drop {
                margin-left: 0;
                margin-right: 10px;
              }
              p {
                display: flex;
                align-items: center;
              }
              > span {
                font-size: 16px;
                color: #00a63c;
                font-weight: bold;
                margin-bottom: 3px;
              }
            }
          }
        }
      }
    }
  }
}
.fixbg_ {
  width: 454px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 315px;
  z-index: 20;
  .con_ {
    padding: 30px 40px;
    background-color: #fff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    color: #000;
    height: 315px;
    position: relative;
    .tit_ {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .email {
      color: #00a63c;
    }
    p {
      line-height: 25px;
    }
    .btn {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      padding: 0 40px;
      span {
        font-weight: bold;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 1);
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 183px;
        cursor: pointer;
        &.on {
          background-color: #00a63c;
          color: #fff;
          border-color: #00a63c;
        }
      }
    }
    .iitem {
      display: flex;
      align-items: center;
      line-height: 27px;
      input {
        border: none;
        background-color: transparent;
        padding: 0 10px;
        outline: none;
        &:focus {
          border: none;
        }
      }
      span {
        width: 100px;
        height: 30px;
        margin-left: 300px;
        margin-top: 10px;
        line-height: 30px;
        background-color: #00a63c;
        color: #fff;
        text-align: center;
        display: inline-block;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .close_ {
    display: block;
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/medilink/about/close.png) center center
      no-repeat;
    background-size: 35px 35px;
    left: 0;
    right: 0;
    bottom: -45px;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
